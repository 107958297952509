<template>
  <v-container fluid>
    <v-sheet rounded min-height="600">
      <common-error
        v-if="isError"
        @fetchData="fetchData"
      />
      <v-skeleton-loader
        v-else-if="isLoading"
        type="
        card-heading,
        list-item-three-line,
        list-item-three-line,
        list-item-three-line,
        list-item-three-line,
        article
        "
      ></v-skeleton-loader>
      <vue-editor
        v-else
        disabled
        v-model="form.parameterValue"
      >
      </vue-editor>
    </v-sheet>
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
  name: 'PrivacyPage',
  components: {
    VueEditor,
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      form: {
        parameterValue: '',
      },
    };
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.isError = false;
      this.$_services.wording.privacyPolicy()
        .then((res) => {
          this.form = res.data;
        })
        .catch(() => {
          this.isError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};

</script>

<style lang="scss" scoped>
::v-deep{
  .ql-toolbar {
    display: none;
  }
  .border {
    border-top: 1px solid grey;
  }
  .ql-container.ql-snow{
    border: none !important;
  }
  p {
    margin-bottom: 0px !important;
  }
}
</style>
